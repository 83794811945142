.block_banner {

	@include transition(all 300ms);

	@include media-breakpoint-up(md) {
		.row {
			flex-wrap: nowrap;
			align-items: center;
		}
		.banner_branding {
			width: auto;
			padding-left: 0;
			padding-right: 30px;
		}

	}

}

	.banner_branding {
		text-align: center;
	}
	
	.banner_nav {
		padding-right: 0;
		text-align: right;
	}
